.Resultados {
  .loading-section {
    height: 50vh !important;
  }
  .main {
    padding-top: 4.5rem;
    padding-bottom: 7rem;
    #resizing_select {
      width: 9rem;
    }
    #width_tmp_select {
      display: none;
    }
    h5 {
      font-size: 1.9rem;
      color: #1d1d1d;
      font-weight: 430;
    }
    h2 {
      font-weight: 430;
      font-size: 5rem;
      color: $bg-secondary;
      span {
        color: $bg-primary;
        font-size: 5rem;
        font-weight: 430;
      }
    }
    span {
      font-size: 1.1rem;
      color: $bg-secondary;
      width: 100%;
      font-weight: 430;
      margin-bottom: 1rem;
      line-height: 1.2;
      text-transform: uppercase;
      button {
        border: none;
        background-color: transparent;
        i {
          color: $bg-primary;
        }
      }
      select {
        border: none;
        line-height: 1.2;
        display: inline-block;
        font-size: 1.1rem;
        // height: 1rem;
        background-color: transparent;
        font-weight: 430;
        color: $secondary-color;
      }
    }
  }
}

@media (max-width: 1400px) {
  .Resultados {
    // .loading-section {
    //   height: 50vh !important;
    // }
    .main {
      // padding-top: 4.5rem;
      // padding-bottom: 7rem;
      // #resizing_select {
      //   width: 9rem;
      // }
      // #width_tmp_select {
      //   display: none;
      // }
      h5 {
        font-size: 1.3rem;
        // color: #1d1d1d;
        // font-weight: 430;
      }
      h2 {
        font-weight: 430;
        font-size: 3.5rem;
        color: $bg-secondary;
        span {
          color: $bg-primary;
          font-size: 3.5rem;
          font-weight: 430;
        }
      }
      span {
        font-size: 0.9rem;

        select {
          font-size: 0.8rem;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .Resultados {
    .main {
      padding-bottom: 2rem;
      padding-top: 10rem;
      button {
        background-color: $bg-terciary;
        color: #fff;
        border: none;
        padding: 0.5rem;
        position: absolute;
        top: 6rem;
        left: 0;
        right: 0;
        z-index: 9;
        width: 100%;
        transform: 0.4s ease all;
        &.filters-top {
          position: fixed;
          top: 0;
        }
      }
      h5 {
        font-size: 0.8rem;
      }
      h2 {
        font-size: 1.8rem;
        width: 80%;
        span {
          font-size: 1.8rem;
        }
      }
      span {
        font-size: 0.7rem;
      }
    }
  }
}
