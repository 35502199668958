.Inicio {
    position: relative;
    font-family: "FR Hopper 430";
    &::before {
        content: "";
        background-color: #606060;
        // background: linear-gradient(
        //   to right,
        //   rgba(116, 116, 116, 1) 0%,
        //   rgba(0, 0, 0, 1) 100%
        // );
        height: 100vh;
        width: 100%;
        z-index: 0;
        position: absolute;
    }
    &::before {
        content: "";
        background-color: #606060;
        height: 2vh;
        width: 100%;
        z-index: 999;
        top: 98vh;
        position: absolute;
        // bottom: 0;
    }
    .main {
        min-height: calc(100vh);
        position: relative;
        z-index: 99;
        // overflow: hidden;
        &::before {
            content: "";
            width: 100%;
            position: absolute;
            //   bottom: 0;
            z-index: 3;
            height: 1.1rem;
            display: block;
            background-image: $bg-terciary;
        }
        .transparencia {
            &::before {
                content: "";
                opacity: 15%;
                width: 100%;
                position: absolute;
                height: 100%;
                z-index: 3;
                // background: transparent
                //   linear-gradient(270deg, var($bg-secondary) 0%, #747474 100%) 0% 0%
                //   no-repeat padding-box;
                background: transparent linear-gradient(270deg, $bg-secondary 0%, #ffffff 108%) 0% 0% no-repeat padding-box;
            }
        }
        .container-fluid {
            .row {
                &::before {
                    content: "";
                    z-index: 2;
                    background-image: url("../../images/bg-top.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    height: 100%;
                    top: 0;
                    display: block;
                    position: absolute;
                    left: -31%;
                    width: 87%;
                }
            }
        }
        &::after {
            content: "";
            background-image: url("../../images/portada-1.jpg");
            background-repeat: no-repeat;
            height: 100vh;
            background-size: cover;
            background-position: cover;
            top: 0;
            width: 100%;
            position: absolute;
            left: 19vw;
            filter: brightness(1);
        }
        #content-main {
            margin-left: 10px;
            height: 100vh;
            padding-top: 8.3rem;
            h1 {
                // font-size: 3.7rem;
                font-size: 60px;
                color: #fff;
                text-align: left;
                font-weight: 430;
            }
            p {
                font-size: 1.1rem;
                color: #fff;
                margin-top: 5rem;
            }
            .div_form_home {
                margin-top: 90px;
                form {
                    div {
                        .content-fields {
                            select {
                                &:hover {
                                    cursor: pointer;
                                }
                            }
                            .icon-arrow {
                                position: absolute;
                                left: 90%;
                                transform: rotate(90deg);
                                z-index: -1;
                            }
                        }
                    }
                }
            }
            form {
                .content-fields {
                    background-color: transparent;
                    border: 2px solid #fff;
                    position: relative;
                    display: flex;
                    align-items: center;
                    &.second {
                        select {
                            padding-left: 1.5rem;
                        }
                    }
                    select,
                    input {
                        background-color: transparent;
                        color: #fff;
                        border: none;
                        font-weight: 100 !important;
                        padding: 1.5rem 0rem;
                        font-size: 1rem;
                        padding-left: 3rem;
                        -webkit-appearance: none;
                        width: 100%;
                        &::placeholder {
                            color: #fff;
                        }
                    }
                    i {
                        color: #fff;
                        position: absolute;
                        z-index: -1;
                        left: 1rem;
                        font-size: 1rem;
                    }
                }
                button.btn {
                    width: 97%;
                    left: 21px;
                    margin-top: 2rem;
                    display: block;
                    padding: 1.81rem;
                    background: #b4b4b4;
                    border: none;
                    transition: background 0.35s ease-in-out;
                    max-height: 88px;
                    height: 75px;
                    line-height: 0.8;
                    p {
                        position: absolute;
                        font-size: 1.4rem;
                        top: -48px;
                        left: 250px;
                        z-index: 5;
                    }
                    span {
                        content: "";
                        z-index: 1;
                        background: white;
                        height: 75px;
                        width: 10%;
                        top: 0px;
                        display: block;
                        position: absolute;
                        left: -9px;
                        transform: skew(343deg);
                        -webkit-transition: all 0.35s;
                        transition: all 0.35s;
                    }
                    &:hover {
                        background: #fff;
                        color: $bg-primary;
                        transition: background 0.35s ease-in-out;
                        p {
                            font-family: "Lato-Regular" !important;
                            color: $bg-primary;
                        }
                        span {
                            content: "";
                            z-index: 1;
                            background: white;
                            height: 75px;
                            width: 90%;
                            top: 0px;
                            display: block;
                            position: absolute;
                            left: -9px;
                            transform: skew(343deg);
                            -webkit-transition: all 0.35s;
                            transition: all 0.35s;
                        }
                    }
                    &::before {
                        content: "";
                        z-index: 2;
                        background-image: url("../../images/search-sq-icon.svg");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        height: 75px;
                        width: 100px;
                        top: 0px;
                        display: block;
                        position: absolute;
                        left: -22px;
                        transform: skew(0deg);
                    }
                    &::after {
                        content: "";
                        z-index: 1;
                        background: #404040;
                        height: 75px;
                        width: 30px;
                        top: 0px;
                        display: block;
                        position: absolute;
                        right: -19px;
                        transform: skew(343deg);
                    }
                }
            }
        }
    }
}

.zindex-3 {
    z-index: 3;
}

@media (min-width: 1920px) {
    .Inicio {
        .main {
            &::after {
                content: "";
                z-index: 1;
                background-image: url("../../images/portada-1.jpg");
                background-repeat: no-repeat;
                background-size: cover;
                display: block;
                position: absolute;
                left: 32vw;
                right: 0;
                filter: brightness(1);
            }
            &::after {
                // left: 40%;
                z-index: 1;
            }
            #content-main {
                form {
                    button.btn {
                        p {
                            top: -48px;
                            left: 287px;
                        }
                        &::before {
                            height: 75px;
                            width: 100px;
                            top: 0px;
                            left: -22px;
                            z-index: 2;
                        }
                        &::after {
                            height: 80px;
                            width: 54px;
                            top: -1px;
                            right: -30px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1500px) {
    .Inicio {
        .main {
            &::after {
                content: "";
                z-index: 1;
                background-image: url("../../images/portada-1.jpg");
                background-repeat: no-repeat;
                // background-position: center;
                //   background-size: cover;
                // background-position: unset;
                height: 100vh;
                display: block;
                position: absolute;
                left: 23vw;
                right: 0;
                filter: brightness(1);
            }
            #content-main {
                form {
                    button.btn {
                        p {
                            top: -40px;
                            left: 230px;
                        }
                        &::before {
                            height: 75px;
                            width: 100px;
                            top: 0px;
                            left: -22px;
                        }
                        &::after {
                            height: 77px;
                            width: 54px;
                            top: 0px;
                            right: -42px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1400px) {
    .Inicio {
        .main {
            &::after {
                left: 10%;
                background-size: cover;
                top: 0;
            }
            #content-main {
                &::before {
                    width: 89%;
                }
                form {
                    button.btn {
                        p {
                            position: absolute;
                            font-size: 1.4rem;
                            top: -35px;
                            left: 210px;
                            z-index: 5;
                        }
                        width: 95%;
                        &::before {
                            height: 75px;
                            width: 100px;
                            top: 0px;
                            left: -22px;
                        }
                        &::after {
                            height: 77px;
                            width: 54px;
                            top: 0px;
                            right: -42px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .Inicio {
        .main {
            #content-main {
                &::before {
                    width: 95%;
                }
                form {
                    button.btn {
                        p {
                            position: absolute;
                            font-size: 1.4rem;
                            top: -35px;
                            left: 170px;
                            z-index: 5;
                        }
                    }
                }
            }
            &::after {
                left: 10%;
            }
        }
    }
}

@media (max-width: 1150px) {
    .Inicio {
        .main {
            #content-main {
                &::before {
                    width: 98%;
                }
            }
            &::after {
                left: 10%;
            }
        }
    }
}

@media (max-width: 1089px) {
    .Inicio {
        .main {
            .container-fluid {
                #content-main {
                    &::before {
                        width: 98%;
                    }
                    form {
                        button.btn {
                            p {
                                position: absolute;
                                font-size: 1.4rem;
                                top: -35px;
                                left: 170px;
                                z-index: 5;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .Inicio {
        &::before {
            display: none;
        }
        .main {
            min-height: 100vh;
            max-height: 100vh;
            .container-fluid {
                .row {
                    &::before {
                        opacity: 0;
                    }
                }
            }
            .transparencia {
                background-color: #0f0f0f;
                opacity: 0.75;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 21;
            }
            &::before {
                z-index: 9999;
                display: none;
            }
            &::after {
                left: 0vw;
                right: 0;
                height: 100vh;
                z-index: -1;
            }
            #content-main {
                margin-left: -10px !important;
                position: relative;
                padding-top: 10vh;
                z-index: 999;
                h1 {
                    font-size: 4vh;
                }
                p {
                    margin-top: 1.5rem;
                    margin-bottom: 1.5rem;
                }
                .div_form_home {
                    margin-top: 6vh;
                }
                form {
                    .content-fields {
                        background-color: rgba(rgb(0, 0, 0), 0.25);
                        input,
                        select {
                            font-size: 0.8rem;
                            padding-left: 2rem;
                        }
                        i {
                            font-size: 0.8rem;
                            z-index: -1;
                            left: .6rem;
                        }
                        span {
                            font-size: 0.6rem;
                        }
                    }
                    button.btn {
                        margin-left: -6px;
                        width: 95%;
                        margin-top: 2rem;
                        font-size: .9rem;
                        display: block;
                        padding: .7rem;
                        height: 55px;
                        p {
                            position: absolute;
                            font-size: 1rem !important;
                            top: -3px !important;
                            left: 45% !important;
                            z-index: 5;
                        }
                        span {
                            height: 55px;
                        }
                        &::before {
                            height: 55px;
                            width: 100px;
                            top: 0px;
                            left: -22px;
                        }
                        &::after {
                            display: none;
                        }
                        &:hover span {
                            height: 55px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 450px) {
    .Inicio {
        .main {
            #content-main {
                form {
                    button.btn {
                        width: 90%;
                    }
                }
            }
        }
    }
}