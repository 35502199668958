header {
    background-color: $bg-primary;
    position: absolute;
    width: 100%;
    z-index: 999;
    box-shadow: 0px 10px 15px #00000033;
    nav {
        width: 100%;
        height: 10.438rem;
        .logo {
            width: auto;
        }
        .logo-footer {
            display: none;
        }
        ul {
            padding-top: 1.2rem;
            li {
                margin-left: 4.5rem;
                list-style: none;
                a {
                    text-decoration: none;
                    font-size: 1.21rem;
                    color: #ffffff;
                    font-weight: 430;
                    text-transform: uppercase;
                    padding-bottom: 0.5rem;
                    &:hover {
                        border-bottom: 2px solid #ffffff;
                        color: #ffffff;
                    }
                }
                &.active {
                    a {
                        border-bottom: 2px solid #ffffff;
                    }
                }
            }
            .social-header {
                line-height: 1.5;
                display: flex;
                .icons {
                    margin-right: 1rem;
                    height: 1.563rem;
                    align-items: center;
                    justify-content: center;
                    vertical-align: middle;
                    width: 1.563rem;
                    align-self: center;
                    // background: $bg-secondary;
                    border-radius: 100%;
                    transition: 0.3s ease all;
                    display: flex;
                    .fix-pt {
                        padding-top: 2px;
                    }
                    a {
                        text-decoration: none;
                        line-height: 0rem;
                        align-items: center;
                    }
                    .icon-facebook {
                        font-size: 1.55rem;
                    }
                    i {
                        transition: 0.3s ease all;
                        color: $bg-secondary;
                        font-size: 1.7rem;
                    }
                    &.fav-icon {
                        background: #efefef;
                        border-radius: 2px;
                        i {
                            color: $bg-primary;
                            font-size: 1rem;
                            &.notActived {
                                text-decoration: none;
                            }
                        }
                        &:hover {
                            background-color: $bg-primary !important;
                            i {
                                color: #fff !important;
                            }
                        }
                    }
                    &:hover {
                        background: #fff !important;
                        i {
                            color: $primary-color !important;
                        }
                    }
                    &:nth-child(1) {
                        margin-left: 2vw;
                    }
                }
            }
        }
    }
    &.results-nav {
        nav ul li a {
            // color: $secondary-color;
        }
    }
    &.home-nav {
        nav ul li a {
            color: #fff;
        }
    }
    &.contact-nav {
        background-color: #fff;
        padding-bottom: 4rem;
        ul {
            li {
                a {
                    color: $secondary-color;
                }
            }
        }
    }
}

@media (max-width: 2220px) {
    header {
        nav {
            ul {
                li {
                    margin-left: 3rem;
                }
            }
        }
        &.home-nav {
            nav ul li {
                &:nth-child(1) {
                    a {
                        color: #fff;
                    }
                }
                &:nth-child(2) {
                    a {
                        color: #fff;
                    }
                }
            }
        }
    }
}

@media (max-width: 2020px) {
    header {
        nav {
            ul {
                li {
                    margin-left: 3rem;
                }
            }
        }
    }
}

@media (max-width: 1920px) {
    header {
        nav {
            ul {
                li {
                    margin-left: 3rem;
                }
            }
        }
    }
}

@media (max-width: 1919px) {
    header {
        nav {
            ul {
                li {
                    margin-left: 3rem;
                }
            }
        }
    }
}

@media (max-width: 1820px) {
    header {
        nav {
            ul {
                li {
                    margin-left: 2rem;
                }
            }
        }
    }
}

@media (max-width: 1720px) {
    header {
        nav {
            ul {
                li {
                    margin-left: 1.1rem;
                }
            }
            .logo {
                width: 22rem;
                position: relative;
                z-index: 999999;
            }
        }
    }
}

@media (max-width: 1620px) {
    header {
        nav {
            ul {
                li {
                    margin-left: 1.2rem;
                }
            }
            .logo {
                width: 18rem;
                position: relative;
                z-index: 999999;
            }
        }
    }
}

@media (max-width: 1600px) {
    header {
        nav {
            ul {
                li {
                    margin-left: 1rem;
                }
                .social-header {
                    display: flex;
                    // line-height: 2rem;
                    .icons {
                        margin-left: 0.4rem;
                    }
                }
                .fav-icon {
                    margin-left: 0.4rem;
                }
            }
        }
    }
}

@media (max-width: 1516px) {
    header {
        nav {
            .logo {
                width: 17rem;
                position: relative;
                z-index: 999999;
            }
        }
    }
}

@media (max-width: 1500px) {
    header {
        nav {
            ul {
                li {
                    margin-left: 1rem;
                }
            }
            .logo {
                width: 16rem;
                position: relative;
                z-index: 999999;
            }
        }
    }
}

@media (max-width: 1400px) {
    header {
        nav {
            ul {
                li {
                    margin-left: 1rem;
                }
            }
            .logo {
                width: 16rem;
                position: relative;
                z-index: 999999;
            }
        }
    }
}

@media (max-width: 1300px) {
    header {
        nav {
            ul {
                li {
                    margin-left: 1rem;
                    a {
                        font-size: 1.1rem;
                    }
                }
                .social-header {
                    .icons {
                        margin-left: 0.4rem;
                    }
                }
                .fav-icon {
                    margin-left: 0.4rem;
                }
            }
            .logo {
                width: 18rem;
                position: relative;
                z-index: 999999;
            }
        }
    }
}

@media (max-width: 1250px) {
    header {
        nav {
            ul {
                li {
                    margin-left: 1rem;
                    text-align: center;
                    a {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    header {
        nav {
            ul {
                li {
                    margin-left: 1rem;
                    &:nth-child(1) {
                        a {
                            color: #fff;
                        }
                    }
                    &:nth-child(2) {
                        a {
                            color: #fff;
                        }
                    }
                    &:nth-child(3) {
                        a {
                            color: #fff;
                        }
                    }
                }
            }
            .logo {
                width: 17rem;
                position: relative;
                z-index: 999999;
            }
        }
    }
}

@media (max-width: 1150px) {
    header {
        nav {
            ul {
                li {
                    margin-left: 0.8rem;
                }
                .social-header {
                    .icons {
                        margin-left: 0.4rem;
                    }
                }
                .fav-icon {
                    margin-left: 0.4rem;
                }
            }
            .logo {
                width: 15rem;
                position: relative;
                z-index: 999999;
            }
        }
    }
}

@media (max-width: 1110px) {
    header {
        nav {
            ul {
                li {
                    margin-left: 0.6rem;
                    a {
                        font-size: 0.9rem;
                    }
                }
            }
            .social-header {
                .icons {
                    margin-left: 0rem;
                }
            }
            .fav-icon {
                margin-left: 0.1rem;
            }
        }
    }
}

@media (max-width: 1016px) {
    header {
        nav {
            ul {
                li {
                    margin-left: 0.6rem;
                    a {
                        font-size: 0.9rem;
                    }
                }
            }
            .social-header {
                .icons {
                    margin-left: 0.5rem !important;
                }
            }
            .fav-icon {
                margin-left: 0.1rem;
            }
            .logo {
                width: 14rem;
            }
        }
    }
}

@media (max-width: 992px) {
    header {
        display: flex;
        align-items: center;
        height: 96px;
        &.contact-nav {
            padding-bottom: 0;
            background-color: rgba(#000, 0.4);
        }
        nav {
            padding-top: 0rem;
            height: unset;
            z-index: 99999;
            .menu-toggle {
                background-color: transparent;
                position: relative;
                z-index: 99999;
                border: none;
                width: unset;
                cursor: pointer;
                display: block;
                transition: 0.4s ease-in all !important;
                i {
                    display: block;
                    background-color: #fff;
                    height: 3px;
                    margin-bottom: 0.45rem;
                    transition: 0.4s ease-in-out all !important;
                    &:nth-child(1) {
                        width: 1.7rem;
                    }
                    &:nth-child(2) {
                        width: 1.7rem;
                    }
                    &:nth-child(3) {
                        width: 1.7rem;
                        margin-bottom: 0;
                    }
                }
                &.open {
                    i {
                        &:nth-child(1) {
                            transform: rotate(-45deg) translate(-0.46rem, 0rem);
                            width: 1.7rem;
                        }
                        &:nth-child(2) {
                            opacity: 0;
                            display: none;
                        }
                        &:nth-child(3) {
                            width: 1.7rem;
                            transform: rotate(225deg) translate(0.4rem, 0.04rem);
                        }
                    }
                }
            }
            .fav-icon {
                position: relative;
                z-index: 999999;
                margin-top: 2px;
                line-height: 1;
                a {
                    text-decoration: none;
                    font-size: 1.4rem;
                    i.notActived {
                        color: $bg-primary;
                        background-color: #efefef;
                        border-radius: 2px;
                        text-decoration: none;
                    }
                }
            }
            .logo {
                width: 12rem;
                position: relative;
                z-index: 999999;
            }
            ul {
                position: fixed;
                left: 200%;
                background-color: rgba($secondary-color, 0.97);
                bottom: 0;
                padding: 0;
                top: 0;
                height: 100vh;
                transition: 0.4s ease all;
                width: 100vw;
                right: 0;
                padding-top: 9rem;
                &.open {
                    left: 0;
                }
                &::before {
                    top: 0;
                    width: 100%;
                    content: "";
                    height: 5.9rem;
                    position: absolute;
                    background-color: $bg-primary;
                }
                li {
                    margin-bottom: 1vh;
                    a {
                        color: rgba(#fff, 1) !important;
                        font-size: 4.5vh;
                        letter-spacing: 0.5px;
                        font-weight: 300;
                    }
                }
                .social-header {
                    position: absolute;
                    bottom: 15vh;
                    .icons {
                        width: 2.5rem;
                        height: 2.5rem;
                    }
                }
            }
        }
    }
}