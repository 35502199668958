.Emprendimiento,
.propiedad .data {
  .contact-main {
    padding: 10vh 0;
    h2 {
      font-size: 5rem;
      font-weight: 300;
      color: $bg-secondary;
    }
    .content-contact {
      width: 100%;
      span,
      a {
        width: 100%;
        font-size: 1.4rem;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
        color: $primary-color;
        font-weight: 400;
        border-bottom: none;
      }
    }
    form {
      padding-top: 10vh;
      padding-bottom: 10vh;
      textarea {
        height: 100%;
      }
      div {
        position: relative;
        .btn_contact_emprendimiento {
          position: absolute !important;
          top: 0 !important;
          right: 5rem !important;
          height: 3.5rem !important;
          line-height: 1 !important;
          &::before {
            top: 0 !important;
            height: 3.5rem !important;
          }
          &:hover {
            &::after {
              transition: 0.2s ease all;
              left: 7.5rem !important;
            }
            &::before {
              transition: 0.2s ease all;
              left: 7.5rem !important;
            }
            p {
              color: $bg-quarter !important;
              z-index: 3;
              &::before {
                background: $bg-primary;
                z-index: -1;
              }
            }
          }
          p {
            padding: 1.4rem;
            &::before {
              content: "";
              position: absolute;
              width: 7.5rem !important;
              height: 3.5rem !important;
              border: 1px solid $bg-primary;
              top: 0 !important;
              left: 0;
              transform: skew(340deg);
            }
          }
        }
      }
    }
  }
}

// @media (max-width: 1400px) {
//   .Emprendimiento,
//   .propiedad .data {
//     .contact-main {
//       form {
//         div {
//           .btn_contact_emprendimiento {
//             &:hover {
//               &::after {
//               }
//               &::before {
//                 top: 0 !important;
//               }
//             }
//             p {
//               padding: 1.4rem;
//               &::before {
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }

@media (max-width: 992px) {
  .Emprendimiento,
  .propiedad .data {
    .contact-main {
      padding: 10vh 0;
      h2 {
        font-size: 2.5rem;
        margin-bottom: 2rem;
      }
      .content-contact {
        width: 100%;
        span,
        a {
          font-size: 1.1rem;
          margin-bottom: 0.6rem;
        }
      }
      form {
        padding-top: 10vh;
        padding-bottom: 5vh;
        textarea {
          height: 100%;
        }
        div {
          .btn_contact_emprendimiento {
            top: 5rem !important;
            left: 7rem !important;
          }
        }
      }
    }
  }
}
