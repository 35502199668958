form {
  .searchcontainer {
    position: relative;
    width: 100%;
    ul {
      list-style: none;
      margin-bottom: 0;
      position: absolute;
      background-color: #fff;
      border: 2px solid $secondary-color;
      border-top: none;
      width: 100.9%;
      left: -2px;
      margin-top: 2px;
      z-index: 999;
      padding: 0;
      .hover-color {
        padding: 1rem 0;
        li {
          padding-left: 3rem;
          color: $secondary-color;
        }
        &:hover {
          background-color: $primary-color;
          li {
            color: #fff;
          }
        }
      }
    }
    ul li {
      cursor: pointer;
    }
    hr {
      border-top: 1px solid rgba(216, 216, 216, 0.753);
    }
  }
  .content-fields {
    background-color: transparent;
    border: 2px solid #fff;
    position: relative;
    display: flex;
    align-items: center;
    &.second {
      select {
        padding-left: 1.5rem;
      }
    }
    select,
    input {
      background-color: transparent;
      color: #fff;
      border: none;
      font-weight: 100;
      line-height: 1;
      padding: 1.5rem 0rem;
      font-size: 1rem;
      padding-left: 3rem;
      -webkit-appearance: none;
      width: 100%;
      &::placeholder {
        color: #fff;
      }
    }
    option {
      color: $secondary-color;
    }
    i {
      color: #fff;
      position: absolute;
      left: 1rem;
      font-size: 1rem;
    }
  }
  button.btn {
    width: 100%;
    margin-top: 2rem;
    display: block;
    padding: 1.81rem;
  }
}

.Resultados {
  .search-results {
    padding: 10.4rem 2% 0 2%;
    form {
      //   padding: 0 8%;
      margin: 3.4rem 8%;
      display: none;
      label {
        margin-bottom: 1rem;
      }
      .content-fields {
        border-color: $secondary-color;
        &.second {
          select {
            padding-left: 1.5rem;
          }
        }
        select,
        input {
          padding: 1.5rem 1.2rem;
          padding-left: 3rem;
          font-weight: 300;
          line-height: unset;
          color: $secondary-color;
          &::placeholder {
            color: $secondary-color;
          }
        }
        i {
          color: $primary-color;
        }
      }
      .btn {
        margin-top: 0;
        padding: 1.6rem;
        border: none;
      }
    }
    border-bottom: 1.063rem solid $bg-terciary;
  }
}
