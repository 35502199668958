.Inicio {
    .about {
        background: linear-gradient( 135deg, rgba(116, 116, 116, 1) 0%, rgba(0, 0, 0, 1) 100%);
        // padding: 12vh 0;
        height: 100vh;
        position: relative;
        .content-logo {
            position: relative;
            .logo-absolute {
                // width: 20vh;
                top: -10vh;
                right: -4vw;
                position: absolute;
                height: 22vh;
                z-index: 3;
            }
        }
        .rotating {
            -webkit-animation: rotating 20s linear infinite;
            -moz-animation: rotating 20s linear infinite;
            -ms-animation: rotating 20s linear infinite;
            -o-animation: rotating 20s linear infinite;
            animation: rotating 20s linear infinite;
        }
        .div_tasa_propiedad {
            height: 100vh;
        }
        .text-content {
            h2 {
                font-weight: 450;
                font-size: 4rem;
            }
            .small_text {
                font-size: 1.5rem;
            }
        }
        .btn {
            position: absolute;
            left: 79%;
            top: 70%;
            z-index: 3;
            border: none;
            padding: 1.8rem 1.3rem;
            background: $bg-primary;
            transform: skew(-15deg);
            &:hover {
                color: #000;
                background: #fff;
            }
        }
        &::after {
            content: "";
            z-index: 2;
            background-image: url("../../images/portada-2.png");
            background-size: cover;
            height: 100%;
            top: 0;
            width: 42%;
            position: absolute;
            right: 0px;
            filter: brightness(0.7);
        }
    }
    // .bg-img {
    //   &::after {
    //     content: "";
    //     z-index: 1;
    //     background-image: url("../../images/portada-2.png");
    //     background-repeat: no-repeat;
    //     /* background-position: center; */
    //     background-size: unset;
    //     height: 100vh;
    //     top: 0px;
    //     display: block;
    //     position: absolute;
    //     left: 59vw;
    //     right: 0;
    //     filter: brightness(0.7);
    //   }
    // }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

// @media (min-width: 992px) {
//   .Inicio {
//     .about {
//       .container-fluid {
//         // max-width: 80%;
//       }
//     }
//   }
// }
@media (max-width: 1600px) {
    .Inicio {
        .about {
            .container-fluid {
                .div_tasa_propiedad {
                    .text-content {
                        max-width: 63%;
                        margin: 0 auto;
                        margin-bottom: 3rem;
                        padding-left: 20px;
                        h2 {
                            font-size: 3.8rem;
                            // margin-bottom: 5vh;
                            z-index: 3;
                        }
                        small {
                            z-index: 3;
                        }
                    }
                    .div_button_img {
                        a {
                            left: 71%;
                        }
                        &::after {
                            left: 50vw;
                            filter: brightness(0.7);
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1300px) {
    .Inicio {
        .about {
            .container-fluid {
                .div_tasa_propiedad {
                    .text-content {
                        max-width: 55%;
                        margin: 0 auto;
                        margin-bottom: 3rem;
                        padding-left: 25px;
                        h2 {
                            font-size: 3.8rem;
                            // margin-bottom: 5vh;
                            z-index: 3;
                        }
                        small {
                            z-index: 3;
                        }
                    }
                    .div_button_img {
                        a {
                            left: 71%;
                        }
                        &::after {
                            left: 47vw;
                            filter: brightness(0.7);
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .Inicio {
        .about {
            .container-fluid {
                .div_tasa_propiedad {
                    .contenedor_text_about {
                        justify-content: start !important;
                        padding: 0;
                        max-width: 100%;
                        margin: 0 auto;
                        margin-bottom: 3rem;
                        h2 {
                            font-size: 3rem;
                            // margin-bottom: 5vh;
                            z-index: 3;
                            display: block;
                            padding: 46px;
                            text-align: center;
                        }
                        small {
                            font-size: 1.5rem;
                            z-index: 3;
                            display: block;
                            padding: 46px;
                            text-align: center;
                            margin: 0 !important;
                        }
                    }
                    .div_button_img {
                        a {
                            left: 36%;
                        }
                        &::after {
                            left: 4vw;
                            top: 0px;
                            // filter: brightness(0.7);
                        }
                    }
                }
            }
            &::after {
                content: "";
                z-index: 2;
                background-image: url("../../images/portada-2.png");
                background-size: cover;
                height: 100%;
                top: 0;
                width: 160%;
                position: absolute;
                right: 0px;
                // filter: brightness(0.7);
            }
            &::before {
                content: '';
                z-index: 3;
                background-color: #000;
                opacity: .5;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}

@media (max-width: 750px) {
    .Inicio {
        .about {
            .container-fluid {
                .div_tasa_propiedad {
                    .contenedor_text_about {
                        h2 {
                            font-size: 2.5rem;
                        }
                        small {
                            font-size: 2rem;
                        }
                    }
                    .div_button_img {
                        a {
                            left: 30%;
                        }
                        &::after {
                            left: -27vw;
                            top: 0px;
                            filter: brightness(0.7);
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .Inicio {
        .about {
            .container-fluid {
                .div_tasa_propiedad {
                    .contenedor_text_about {
                        h2 {
                            font-size: 2rem;
                        }
                        small {
                            font-size: 1.5rem;
                        }
                    }
                    .div_button_img {
                        a {
                            left: 30%;
                        }
                        &::after {
                            left: -27vw;
                            top: 0px;
                            filter: brightness(0.7);
                        }
                    }
                    .div_button_img {
                        a {
                            left: 30%;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 400px) {
    .Inicio {
        .about {
            .container-fluid {
                .div_tasa_propiedad {
                    .contenedor_text_about {
                        h2 {
                            font-size: 1.5rem;
                        }
                        small {
                            font-size: 1.2rem;
                        }
                    }
                    .div_button_img {
                        a {
                            left: 30%;
                        }
                        &::after {
                            left: -91vw;
                            top: 0px;
                            filter: brightness(0.7);
                        }
                    }
                    .div_button_img {
                        a {
                            top: 82%;
                            left: 12%;
                        }
                    }
                }
            }
        }
    }
}