footer {
    background-color: $bg-secondary;
    padding-top: 5rem;
    padding-bottom: 7rem;
    z-index: 999;
    position: relative;
    .enrollment-wrap {
        width: 140px;
        display: flex;
        color: #fff;
        padding: 0.5rem 1rem;
        transform: skew(-15deg);
        box-sizing: content-box;
        border: solid 2px $bg-primary;
        justify-content: space-between;
        .enrollment-text {
            font-family: "Lato-Medium";
        }
        .enrollment-number {
            font-family: "Raleway-ExtraBold";
            &::before {
                top: -1px;
                right: -1px;
                width: 35%;
                z-index: -1;
                content: "";
                height: 104%;
                position: absolute;
                background: $bg-primary;
            }
        }
        &:hover {
            border: solid 2px #fff;
            background: #fff;
            &::before {
                z-index: 1;
                width: 100%;
                display: flex;
                content: "CUCICBA";
                background: #fff;
                align-items: center;
                color: $bg-secondary;
                justify-content: center;
                font-family: "Raleway-ExtraBold";
            }
            .enrollment-text,
            .enrollment-number {
                display: none;
            }
        }
    }
    .title-btn {
        color: #fff;
        font-size: 1rem;
        margin-bottom: 0.5rem;
        font-weight: 400;
    }
    .btn {
        padding: 0.9rem 3.5vw;
        position: relative;
        background: $tertiary-color;
        width: min-content;
        font-weight: 500;
        font-size: 0.9rem;
        padding-left: calc(3.5vw + 3rem);
        i {
            position: absolute;
            left: 1px;
            top: 1px;
            font-size: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            vertical-align: middle;
            color: #fc4147;
            bottom: 1px;
            width: 3rem;
            background-color: $secondary-color;
        }
        &:hover {
            background: $secondary-color;
            border: 2px solid $primary-color;
            color: $primary-color;
            i {
                background: $primary-color;
                color: #fff;
            }
        }
    }
    .content {
        color: #fff;
        h5,
        p,
        a {
            font-size: 1rem;
            margin-bottom: 0;
            color: #fff;
        }
        h5 {
            font-weight: 400;
        }
        p {
            margin-bottom: 3rem;
            font-weight: 100;
            a {
                margin-top: 0;
                text-decoration: none;
                transition: 0.4s ease all;
                &:hover {
                    color: $primary-color;
                }
            }
        }
        span {
            font-weight: 300;
            a {
                color: $primary-color;
                font-weight: 500;
                text-decoration: none;
                transition: 0.4s ease all;
                &:hover {
                    color: #fff;
                }
            }
        }
    }
    img {
        // width: 12rem;
        display: none;
    }
    .copy {
        font-size: 1rem;
        margin-top: 1rem;
        color: #fff;
        a {
            color: #1ba1fc;
            text-decoration: none;
        }
    }
    .qr {
        object-fit: contain;
        height: auto;
    }
    .footer-nav {
        header {
            position: static;
            background: transparent;
            box-shadow: none;
            .container-fluid {
                margin: 0;
                padding: 0;
                max-width: 100%;
                nav {
                    .logo-header {
                        display: none !important;
                    }
                    .logo-footer {
                        display: block;
                    }
                    ul {
                        li {
                            a {
                                &:hover {
                                    border-bottom: 2px solid $bg-primary;
                                }
                            }
                            &.active {
                                a {
                                    border-bottom: 2px solid $bg-primary;
                                }
                            }
                        }
                        .social-header {
                            .icons {
                                .icon-instagram {
                                    font-size: 1.85rem !important;
                                    // margin-right: 0.5rem;
                                }
                                margin-right: 1.1rem;
                                height: 1.6rem;
                                i:not(.notActived) {
                                    color: #fff;
                                    padding-left: 1rem;
                                }
                                &:nth-child(1) {
                                    margin-left: 3vw;
                                    a {
                                        .icon-facebook {
                                            font-size: 1.6rem !important;
                                            margin-right: 2.5rem;
                                        }
                                    }
                                }
                                &:hover {
                                    background: none !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    footer {
        .btn.btn-access {
            margin: 1rem auto 2rem;
        }
        img {
            width: 50vw;
            display: inline;
        }
        .footer-nav {
            display: none;
        }
        .enrollment-wrap {
            margin: 0 auto;
        }
    }
}