.propiedad {
  .data {
    padding-top: 10vh;
    h2 {
      font-size: 5.5rem;
      margin-bottom: 0;
      line-height: 1;
      font-weight: 400;
      color: $bg-secondary;
      &.sub_title {
        font-weight: 200;
        margin-top: 2rem;
      }
    }
    .div_button_propiedad {
      .btn {
        padding: 0.4rem 1.5rem;
        color: $primary-color;
        font-size: 0.7rem;
        margin-right: 0.7rem;
        transform: skew(341deg);
        box-shadow: 0px 0px 5px $bg-terciary;
        &:hover {
          background: $tertiary-color;
          color: $bg-primary;
          i {
            color: $bg-primary;
          }
        }
        i {
          margin-right: 1rem;
          font-size: 20px;
          transform: rotate(352deg);
          &.icon-send {
            transform: rotate(357deg);
          }
        }
        &.small {
          width: 3rem;
          height: 3rem;
          padding: 0.4rem;
          color: $bg-quarter;
          i {
            margin-right: 0rem;
          }
          &:hover {
            border: none;
            // border: 2px solid $secondary-color;
            background: $bg-quarter;
          }
        }
        &.active {
          background: #fff;
          color: $primary-color;
        }
      }
    }

    .tag {
      padding: 0.5rem 1.3rem;
      font-size: 0.8rem;
      font-weight: 300;
      color: $bg-quarter;
      background: $bg-secondary;
      position: relative;
      margin-right: 40px !important;
      &::before {
        content: "";
        background: linear-gradient(300deg, $bg-secondary 40%, transparent 7%);
        height: 35px;
        width: 37px;
        top: 0;
        z-index: 3;
        left: -34px;
        position: absolute;
      }
      &::after {
        content: "";
        background: linear-gradient(120deg, $bg-secondary 40%, transparent 7%);
        height: 35px;
        width: 37px;
        top: 0;
        z-index: 3;
        right: -36px;
        position: absolute;
      }
      &.primary-tag,
      &.Alquiler {
        color: $bg-quarter;
        background: $bg-terciary;
        &::before {
          background: linear-gradient(300deg, $bg-terciary 40%, transparent 7%);
        }
        &::after {
          background: linear-gradient(120deg, $bg-terciary 40%, transparent 7%);
        }
      }
    }
    .item {
      .tick {
        // background: linear-gradient(90deg, #c42127, #a5252a);
        // color: red;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 100%;
        i {
          color: $bg-primary;
          font-size: 1rem;
        }
      }
    }
    .item-extra {
      margin-top: 0.5rem;
      font-size: 1.5rem;
      color: #1d1d1d;
      align-items: center;
      i {
        color: #797979;
        text-align: center;
        font-size: 1rem;
        &.icon-gym {
          font-size: 0.7rem;
        }
        &.icon-home {
          font-size: 1.1rem;
          margin-right: 1.8rem !important;
          display: block;
        }
      }
    }
    hr {
      height: 1px;
      color: #000000;
      margin: 7vh 0;
    }
    p {
      font-size: 1.5rem;
      margin-top: 3rem;
      line-height: 1.2;
      font-weight: 400;
      color: $secondary-color;
    }
    .content-price {
      display: block;
      margin-bottom: 5vh;
      h3 {
        color: $bg-terciary;
        display: inline-flex;
        width: 100%;
        align-items: center;
        // justify-content: flex-end;
        position: relative;
        // min-width: 30rem;
        // padding-left: 4rem;
        font-size: 3.75rem;
        font-weight: 400;
        &::before {
          content: "";
          width: 2rem;
          height: 2rem;
          margin-right: 2.5rem;
          display: inline-block;
          position: absolute;
          left: 0;
        }
        // &.Alquiler {
        //   // &::before {
        //   //   background: $tertiary-color;
        //   // }
        // }
        // &.Venta {
        //   &::before {
        //     border: 1px solid $primary-color;
        //     background: #fff;
        //   }
        // }
      }
    }
    .contact-main {
      .btn {
        padding: 1.1rem 2.5rem;
        padding-right: calc(2.5rem + 3.5rem);
        &:hover {
          color: #fff;
        }
      }
    }
  }
}

@media (min-width: 1919px) {
  .propiedad {
    .data {
      .tag {
        padding: 0.5rem 1rem;
      }
    }
  }
}

@media (max-width: 1919px) {
  .propiedad {
    .data {
      .tag {
        padding: 0.58rem 1rem;
      }
    }
  }
}

@media (max-width: 1500px) {
  .propiedad {
    .data {
      .tag {
        padding: 0.69rem 1rem;
      }
    }
  }
}
@media (max-width: 1400px) {
  .propiedad {
    .data {
      .tag {
        padding: 0.8rem 1rem;
      }
    }
  }
}

@media (max-width: 400px) {
  .propiedad {
    .data {
      .tag {
        padding: 0.58rem 1rem !important;
        margin-left: 20px;
      }
    }
  }
}

@media (max-width: 992px) {
  .propiedad {
    .data {
      padding-top: 5vh;
      h2 {
        font-size: 2.5rem;
        &.sub_title {
          font-size: 2rem;
        }
        .location {
          font-size: 1.3rem;
          margin: 0.5rem 0 2rem 0;
        }
      }
      .btn {
        padding: 0rem 1rem;
        font-size: 0.6rem;
        margin-right: 0.5rem;
      }
      .tag {
        padding: 0.6rem;
        margin-bottom: 3vh;
        font-size: 0.7rem;
        margin-left: 20px;
      }
      .item {
        .tick {
          width: 1rem;
          height: 1rem;
          border-radius: 100%;
        }
      }
      .item-extra {
        font-size: 1rem;
        overflow-x: scroll;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        &:nth-child(4) {
          margin-bottom: 2vh;
        }
      }
      p {
        font-size: 1.1rem;
      }
      .content-price {
        h3 {
          font-size: 2.2rem;
        }
      }
      h3 {
        font-size: 3rem;
        margin-bottom: 7vh;
      }
      .contact-main {
        form {
          padding-top: 0vh !important;
        }
        h2 {
          font-size: 2rem !important;
        }
        .btn {
          font-size: 0.9rem;
          padding: 1rem 2rem;
          padding-right: calc(2rem + 3.5rem);
        }
      }
    }
  }
}
